import { Form, FormInstance, InputNumber, Radio, Select, Space } from "antd";

import styles from "./BucketGroupPriceFormItems.module.css";
import { useEffect } from "react";

const bucketGroupPriceFieldLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
};
interface IPropsBucketGroupPriceFormItems {
  formItemName: string;
  label: string;
  form: FormInstance<any>;
  showMaxField?: boolean;
}

export default function BucketGroupPriceFormItems({
  formItemName,
  form,
  label,
}: IPropsBucketGroupPriceFormItems) {
  // Custom validator for the Partner Purchase Price (value)
  const validateValue = (_: any, value: any) => {
    const max = form.getFieldValue(["bucketGroups", formItemName, "max"]);
    if (max !== undefined && value > max) {
      return Promise.reject(new Error("Exceeds max!"));
    }
    return Promise.resolve();
  };

  // Trigger re-validation when Max Price changes
  useEffect(() => {
    form.validateFields([["bucketGroups", formItemName, "value"]]);
  }, [form.getFieldValue(["bucketGroups", formItemName, "max"])]);
  return (
    <>
      <br />
      <span style={{ fontWeight: "bolder" }}> {label}</span>
      <br />
      <br />
      <Space align="baseline">
        <Space size={0}>
          <Form.Item
            label={"Partner Purchase Price"}
            name={["bucketGroups", formItemName, "value"]}
            rules={[
              { required: true, message: "!*" },
              { validator: validateValue }, // Custom validation
            ]}
          >
            <InputNumber
              controls={false}
              className={styles.bucket_value_input}
              min={0}
              style={{ width: "55px" }}
            />
          </Form.Item>
          <Form.Item
            required={true}
            rules={[{ required: true, message: "!*" }]}
            name={["bucketGroups", formItemName, "valueType"]}
          >
            <Radio.Group>
              <Radio.Button value={"Percentage"}>%</Radio.Button>
              <Radio.Button value={"Fixed"}>FIXED</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Space>
        <Form.Item
          label="Max Price"
          rules={[{ required: true, message: "!*" }]}
          name={["bucketGroups", formItemName, "max"]}
        >
          <InputNumber
            controls={false}
            className={styles.bucket_value_input}
            min={0}
            style={{ width: "55px" }}
            onChange={() => {
              form.validateFields([["bucketGroups", formItemName, "value"]]);
            }} // Trigger validation on change
          />
        </Form.Item>
      </Space>
    </>
  );
}
