import { BucketName, BucketNameField } from "./businessFull.interface";
import {
  PartnerBucketGroupPrice,
  PartnerBucketGroups,
} from "./partnerDeal.interface";
import { PlatformType } from "./paxTerminal.interface";

export type IPartner = IPartnerDetails & {
  _id: string;
  purchPriceProfiles: IPurchPriceProfile[];
  createdAt: string;
  teamMembers: IPartnerTeamMember[];
  refCode: string;
  defaultPricing: PartnerBucketGroups;
  softwareVendorPricing: PartnerBucketGroups;
  terminalCouplingPlatform: PlatformType;
  partnerType: PartnerType;
  role: PartnerRole;
  settings?: IPartnerSettings;
  softwareVendorId?: string;
};

export type IPartnerDetails = {
  name: string;
  ownerName: string;
  phone: { number: string; countryCode: string };
  email: string;
};

export type IPurchPriceProfile = {
  _id: string;
  name: string;
  bucketGroups: IBucketGroups;
  whitelist?: string[];
  pricingTemplate?: PricingTemplate;
};
export enum PricingTemplate {
  Default = "default", //domestic debit, european, international and commercial
  EuropeanDebitSplit = "europeanDebitSplit", //domestic debit, european debit, european, international and commercial
}
export type PartnerBucketGroupMinMaxPrice = PartnerBucketGroupPrice & {
  max: number;
  min?: number;
};
export interface IBucketGroups {
  domesticDebitCards: PartnerBucketGroupMinMaxPrice;
  europeanDebitCards?: PartnerBucketGroupMinMaxPrice;
  european: PartnerBucketGroupMinMaxPrice;
  internationalAndCommercial: PartnerBucketGroupMinMaxPrice;
}
export type BucketGroupFieldNames =
  | "domesticDebitCards"
  | "europeanDebitCards"
  | "european"
  | "internationalAndCommercial";

export type IPartnerTeamMember = {
  name: string;
  email: string;
  _id: string;
  createdAt: string;
};
export interface IPartnerSettings {
  webhooks: {
    merchantVerified: { url: string };
    paymentComplete: { url: string };
  };
}
export enum PartnerType {
  SoftwareVendor = "SoftwareVendor", // Independent Software Vendor (ISV)
  Reseller = "Reseller", // Value-Added Reseller (VAR)
  FullServicePartner = "FullServicePartner", // Full-Service Partner (software vendor + reseller)
  FullServiceWithResellers = "FullServiceWithResellers", // Full-Service Partner with resellers (internal + external resellers)
}
export enum PartnerRole {
  Admin = "Admin", // Admin
  User = "User", // User
}

export const PricingTemplateBucketFieldMap = {
  [PricingTemplate.EuropeanDebitSplit]: {
    [BucketNameField.DomesticDebit]: "domesticDebit",
    [BucketNameField.EuropeanDebit]: "europeanDebit",
    [BucketNameField.DomesticCredit]: "european",
    [BucketNameField.EuropeanCredit]: "european",
    [BucketNameField.DomesticCorporateCredit]: "internationalAndCommercial",
    [BucketNameField.DomesticCorporateDebit]: "internationalAndCommercial",
    [BucketNameField.EuropeanCorporateCredit]: "internationalAndCommercial",
    [BucketNameField.EuropeanCorporateDebit]: "internationalAndCommercial",
    [BucketNameField.InternationalCorporateCredit]:
      "internationalAndCommercial",
    [BucketNameField.InternationalCorporateDebit]: "internationalAndCommercial",
    [BucketNameField.InternationalCredit]: "internationalAndCommercial",
    [BucketNameField.InternationalDebit]: "internationalAndCommercial",
  },
  [PricingTemplate.Default]: {
    [BucketNameField.DomesticDebit]: "domesticDebit",
    [BucketNameField.EuropeanDebit]: "european",
    [BucketNameField.DomesticCredit]: "european",
    [BucketNameField.EuropeanCredit]: "european",
    [BucketNameField.DomesticCorporateCredit]: "internationalAndCommercial",
    [BucketNameField.DomesticCorporateDebit]: "internationalAndCommercial",
    [BucketNameField.EuropeanCorporateCredit]: "internationalAndCommercial",
    [BucketNameField.EuropeanCorporateDebit]: "internationalAndCommercial",
    [BucketNameField.InternationalCorporateCredit]:
      "internationalAndCommercial",
    [BucketNameField.InternationalCorporateDebit]: "internationalAndCommercial",
    [BucketNameField.InternationalCredit]: "internationalAndCommercial",
    [BucketNameField.InternationalDebit]: "internationalAndCommercial",
  },
};
export const PricingTemplateBucketNameMap = {
  [PricingTemplate.EuropeanDebitSplit]: {
    [BucketName.DomesticDebit]: "domesticDebitCards",
    [BucketName.EuropeanDebit]: "europeanDebitCards",
    [BucketName.DomesticCredit]: "european",
    [BucketName.EuropeanCredit]: "european",
    [BucketName.DomesticCorporateCredit]: "internationalAndCommercial",
    [BucketName.DomesticCorporateDebit]: "internationalAndCommercial",
    [BucketName.EuropeanCorporateCredit]: "internationalAndCommercial",
    [BucketName.EuropeanCorporateDebit]: "internationalAndCommercial",
    [BucketName.InternationalCorporateCredit]: "internationalAndCommercial",
    [BucketName.InternationalCorporateDebit]: "internationalAndCommercial",
    [BucketName.InternationalCredit]: "internationalAndCommercial",
    [BucketName.InternationalDebit]: "internationalAndCommercial",
  },
  [PricingTemplate.Default]: {
    [BucketName.DomesticDebit]: "domesticDebitCards",
    [BucketName.EuropeanDebit]: "european",
    [BucketName.DomesticCredit]: "european",
    [BucketName.EuropeanCredit]: "european",
    [BucketName.DomesticCorporateCredit]: "internationalAndCommercial",
    [BucketName.DomesticCorporateDebit]: "internationalAndCommercial",
    [BucketName.EuropeanCorporateCredit]: "internationalAndCommercial",
    [BucketName.EuropeanCorporateDebit]: "internationalAndCommercial",
    [BucketName.InternationalCorporateCredit]: "internationalAndCommercial",
    [BucketName.InternationalCorporateDebit]: "internationalAndCommercial",
    [BucketName.InternationalCredit]: "internationalAndCommercial",
    [BucketName.InternationalDebit]: "internationalAndCommercial",
  },
};
