import React, { useEffect, useState } from "react";

import { Button, Form, Input, InputNumber } from "antd";
import styles from "./BucketGroupsForm.module.css";
import {
  PartnerBucketGroups,
  ValueType,
} from "../../../interfaces/partnerDeal.interface";
import { IBucketGroups } from "../../../interfaces/partner.interface";

export const valueTypeLabel = (valueType?: ValueType) => {
  let label = valueType === "Percentage" ? "%" : valueType;
  return <span>{label}</span>;
};
const partnerDealsFormFieldLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 18 },
};

interface IBucketGroupsForm {
  bucketGroups?: PartnerBucketGroups;
  bucketGroupsRange: IBucketGroups;
  onSubmit: (value: { bucketGroups: PartnerBucketGroups }) => void;
  minLabel?: string;
  maxLabel?: string;
}
export default function BucketGroupsForm({
  onSubmit,
  bucketGroups,
  bucketGroupsRange,
  minLabel,
  maxLabel,
}: IBucketGroupsForm) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    populateForm();
  }, []);

  const populateForm = () => {
    // if (!purchPriceProfiles) return;
    // initialProfile.buckets[0].value = 0.023;

    form.setFieldsValue({
      bucketGroups: bucketGroups ? bucketGroups : { ...bucketGroupsRange },
    });
  };

  const PricingFormItem = (
    type:
      | "domesticDebitCards"
      | "europeanDebitCards"
      | "european"
      | "internationalAndCommercial"
  ) => {
    let label =
      type === "domesticDebitCards"
        ? "Domestic Debit Cards"
        : type === "europeanDebitCards"
        ? "European Debit Cards"
        : type === "european"
        ? "E.E.A Cards"
        : "Non E.E.A & Corporate Cards";
    let toolTipText =
      type === "domesticDebitCards"
        ? "Includes domestic debit cards only."
        : type === "europeanDebitCards"
        ? "Includes all European debit cards that are not commerical or international."
        : type === "european"
        ? `Includes all European credit cards (and European debit, as long as no european debit group is set) that are not commerical or international as well as all domestic credit cards.`
        : "Includes all commerical & international debit and credit cards.";
    return (
      <>
        <Form.Item
          label={label}
          name={["bucketGroups", type, "value"]}
          labelCol={{ ...partnerDealsFormFieldLayout.labelCol }}
          labelAlign="left"
          rules={[{ required: true, message: "!*" }]}
          tooltip={toolTipText}
          help={`${minLabel || ""} ${bucketGroupsRange[type]?.min} | ${
            maxLabel || ""
          } ${bucketGroupsRange[type]?.max} `}
          className={styles.bucket_group_form_item}
        >
          <InputNumber
            controls={false}
            className={styles.bucket_value_input}
            min={bucketGroupsRange[type]?.min || Number.NEGATIVE_INFINITY}
            max={bucketGroupsRange[type]?.max || Number.POSITIVE_INFINITY}
            style={{ width: "140px" }}
            addonAfter={valueTypeLabel(bucketGroupsRange[type]?.valueType!)}
          />
        </Form.Item>

        <Form.Item
          hidden
          name={["bucketGroups", type, "valueType"]}
          initialValue={bucketGroupsRange[type]?.valueType!}
        >
          <Input />
        </Form.Item>
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div>
        <Form
          onFinish={onSubmit}
          form={form}
          style={{
            minWidth: 600,
          }}
        >
          {/* {purchPriceProfiles && purchPriceProfiles?.length > 0 && (
            <Form.Item
              label={"Rapyd Price Range"}
              name={["purchPriceProfileId"]}
              labelCol={{ ...partnerDealsFormFieldLayout.labelCol }}
              labelAlign="left"
              hidden={purchPriceProfiles?.length === 1}
            >
              <Select
                style={{ width: "200px" }}
                onChange={handlePurchPriceProfileChange}
                disabled={disableProfileSelection}
                options={purchPriceProfiles?.map((pp) => ({
                  value: pp._id,
                  label: pp.name,
                }))}
              ></Select>
            </Form.Item>
          )} */}

          {PricingFormItem("domesticDebitCards")}
          {bucketGroupsRange?.europeanDebitCards &&
            PricingFormItem("europeanDebitCards")}

          {PricingFormItem("european")}

          {PricingFormItem("internationalAndCommercial")}

          <Form.Item>
            <Button style={{ float: "right" }} type="primary" htmlType="submit">
              OK
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

{
  /* 
          <Form.List name={"buckets"}>
            {(fields) => (
              <div>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} align="baseline">
                    <Form.Item {...restField} name={[name, "name"]}>
                      <Input
                        className={styles.bucket_name_input}
                        disabled
                      ></Input>
                    </Form.Item>

                    <Form.Item {...restField} name={[name, "value"]}>
                      <InputNumber
                        controls={false}
                        className={styles.bucket_value_input}
                        min={0}
                      />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "valueType"]}>
                      <Select
                        className={styles.bucket_value_input}
                        style={{ width: "85px" }}
                      >
                        <Select.Option value={"Percentage"}>%</Select.Option>
                        <Select.Option value={"Fixed"}>FIXED</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "max"]}>
                      <InputNumber
                        controls={false}
                        className={styles.bucket_value_input}
                        min={0}
                        max={3}
                      />
                    </Form.Item>
                  </Space>
                ))}
              </div>
            )}
          </Form.List>  */
}
