import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  createPartnerThunk,
  selectCurrentPartner,
  selectSoftwareVendors,
} from "../../partnersSlice";
import styles from "./CreatePartnerForm.module.css";
import {
  IPartner,
  IPartnerDetails,
  IPurchPriceProfile,
  PartnerType,
  PricingTemplate,
} from "../../../../interfaces/partner.interface";
import PurchPriceProfileForm from "../purch-price-profile-form/PurchPriceProfileForm";
import { DEFAULT_PURCH_PRICE_PROFILE } from "../../utils/defaultPurchPriceProfile";
import PartnerDetailsForm from "../partner-details-form/partnerDetailsForm";
import CreateEditBucketGroupsForm from "../../../partner-deals/components/create-edit-bucket-groups-form/CreateEditBucketGroupsForm";
import { PartnerBucketGroups } from "../../../../interfaces/partnerDeal.interface";
import { PlatformType } from "../../../../interfaces/paxTerminal.interface";
import PartnerTypeForm from "../partner-type-form/partnerTypeForm";

const { Title, Text } = Typography;
const partnerFormFieldLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
};
interface IPropsCreatePartnerFormModal {
  visible: boolean;
  setVisible: any;
}

export default function CreatePartnerForm({
  visible,
  setVisible,
}: IPropsCreatePartnerFormModal) {
  //   const currentPartner = useAppSelector(selectCurrentPartner);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [partner, setPartner] = useState<Partial<IPartner>>({});
  const [step, setStep] = useState(1);
  const handleSubmit = async (values: Partial<IPartner>) => {
    setLoading(true);

    let partner: Partial<IPartner> = values;
    await dispatch(createPartnerThunk(partner));
    setLoading(false);
  };

  const handleReset = () => {
    form.resetFields();
    setPartner({});
    setStep(1);
  };

  const handleCancel = () => {
    handleReset();
    setVisible(false);
  };

  const onSubmitPartnerDetails = (partnerDetails: IPartnerDetails) => {
    setPartner((prev) => ({ ...prev, ...partnerDetails }));
    setStep(2);
  };
  const onSubmitPartnerType = ({
    partnerType,
    softwareVendorId,
  }: {
    partnerType: PartnerType;
    softwareVendorId?: string;
  }) => {
    setPartner((prev) => ({ ...prev, partnerType, softwareVendorId }));
    setStep(3);
  };
  const onSubmitPurchPriceProfile = (
    purchPriceProfile: IPurchPriceProfile & { pricingTemplate: PricingTemplate }
  ) => {
    setPartner((prev) => ({
      ...prev,
      pricingTemplate: purchPriceProfile.pricingTemplate,
      purchPriceProfiles: [purchPriceProfile],
    }));

    setStep(4);
  };
  const onSubmitDefaultPricing = (params: {
    purchPriceProfileId: string;
    bucketGroups: PartnerBucketGroups;
  }) => {
    console.log("defaultPricing", params);
    setPartner((prev) => ({
      ...prev,
      defaultPricing: params.bucketGroups,
    }));
    setStep(5);
  };
  const onSubmitSoftwareVendorPricing = (params: {
    bucketGroups: PartnerBucketGroups;
  }) => {
    console.log("software vendor pricing", params);
    setPartner((prev) => ({
      ...prev,
      softwareVendorPricing: params.bucketGroups,
    }));
    setStep(6);
  };
  const handleCouplingPlatformSelection = (platform: PlatformType) => {
    setPartner((prev) => ({
      ...prev,
      terminalCouplingPlatform: platform,
    }));
  };
  const handleSoftwareVendorSelection = (softwareVendorId: string) => {
    setPartner((prev) => ({
      ...prev,
      softwareVendorId,
    }));
  };
  const handlePartnerTypeSelection = (partnerType: PartnerType) => {
    setPartner((prev) => ({
      ...prev,
      partnerType,
    }));
  };

  const onCreatePartner = async () => {
    setLoading(true);

    const result = (await dispatch(createPartnerThunk(partner))) as any;
    console.log("result", result);
    if (!result?.error?.name) handleCancel();

    setLoading(false);

    try {
    } catch (error) {
    } finally {
    }
  };

  const goBack = () => {
    setStep((prev) => {
      if (prev <= 1) return prev;
      return prev - 1;
    });
  };
  const goNext = () => {
    setStep((prev) => {
      if (prev >= 7) return prev;
      return prev + 1;
    });
  };

  const getInitialSoftwareVendorPrice = (partner: Partial<IPartner>) => {
    let price = {} as PartnerBucketGroups;
    price.domesticDebitCards = { value: 0, valueType: "Fixed" };
    console.log("partner", partner);
    if (
      partner?.purchPriceProfiles &&
      partner.purchPriceProfiles[0].pricingTemplate ===
        PricingTemplate.EuropeanDebitSplit
    ) {
      price.europeanDebitCards = { value: 0, valueType: "Fixed" };
    }
    price.european = { value: 0, valueType: "Percentage" };
    price.internationalAndCommercial = { value: 0, valueType: "Percentage" };
    return price;
  };

  return (
    <div>
      <Modal
        visible={visible}
        title={"Create New Partner"}
        onCancel={handleCancel}
        footer={null}
        width={650}
      >
        <div>
          {step !== 1 && (
            <Button type="default" onClick={goBack}>
              Back
            </Button>
          )}
          <br />
          <br />
          <div className={styles.form_container}>
            {step === 1 && (
              <div>
                <Title level={4} style={{ marginBottom: 2 }}>
                  Partner Details
                </Title>
                {/* <Text type="secondary">dsd</Text> */}
                <br />
                <br />

                <PartnerDetailsForm
                  partnerDetails={{
                    name: partner?.name,
                    ownerName: partner?.ownerName,
                    email: partner?.email,
                    phone: partner?.phone,
                  }}
                  onSubmit={onSubmitPartnerDetails}
                ></PartnerDetailsForm>
              </div>
            )}
            {step === 2 && (
              <div>
                <Title level={4} style={{ marginBottom: 2 }}>
                  Partner Type
                </Title>
                <Text type="secondary">
                  Software vendor- technical integration partner.
                  <br />
                  Reseller- Reseller of software vendor.
                  <br />
                  Full Service Partner- techincal integration partner and its
                  own reseller.
                  <br />
                  Full Service Partner with Resellers- technical integration
                  partner with its own reseller and external resellers.
                </Text>
                <br />
                <br />
                <PartnerTypeForm
                  onSubmit={onSubmitPartnerType}
                  partnerType={partner.partnerType}
                  softwareVendorId={partner.softwareVendorId}
                ></PartnerTypeForm>
              </div>
            )}
            {step === 3 && (
              <div>
                <Title level={4} style={{ marginBottom: 2 }}>
                  Setup Purchase Price Profile
                </Title>
                <Text type="secondary">
                  Limit the range of prices the partner can set up for his
                  merchants and set the minimum price Klearly will get.
                </Text>
                <br />
                <br />
                <PurchPriceProfileForm
                  initialProfile={
                    (partner.purchPriceProfiles &&
                      partner.purchPriceProfiles[0]) ||
                    DEFAULT_PURCH_PRICE_PROFILE
                  }
                  onSubmit={onSubmitPurchPriceProfile}
                  hideNameField={true}
                  hideWhitelistField={true}
                  removeIdField={true}
                  loading={loading}
                ></PurchPriceProfileForm>
              </div>
            )}
            {step === 4 && (
              <div>
                <Title level={4} style={{ marginBottom: 2 }}>
                  Default Price
                </Title>
                <Text type="secondary">
                  This will be the default price of a partner's merchant if no
                  partner pricing deal is found or premade by the partner at the
                  time the merchant is created.
                </Text>
                <br />
                <br />
                <div className="center">
                  <CreateEditBucketGroupsForm
                    purchPriceProfiles={partner.purchPriceProfiles!}
                    onSubmit={onSubmitDefaultPricing}
                  />
                </div>
              </div>
            )}
            {step === 5 && (
              <div>
                <Title level={4} style={{ marginBottom: 2 }}>
                  Software Vendor Price (Optional)
                </Title>
                <Text type="secondary">
                  The price allocated to the Klearly software vendor of this
                  reseller. If no software vendor exists, no price will be
                  allocated.
                </Text>
                <br />
                <br />
                <div className="center">
                  <CreateEditBucketGroupsForm
                    bucketGroupsToEdit={getInitialSoftwareVendorPrice(partner)}
                    onSubmit={onSubmitSoftwareVendorPricing}
                  />
                </div>
              </div>
            )}
            {step === 6 && (
              <div>
                <Title level={4} style={{ marginBottom: 2 }}>
                  Terminal Coupling Platform
                </Title>
                <Text type="secondary">
                  Choose which Pax coupling platform the partner will use:
                </Text>
                <br />
                <br />
                <div className="center" style={{ flexDirection: "column" }}>
                  <Select
                    style={{
                      minWidth: "250px",
                      marginBottom: "30px",
                      alignSelf: "start",
                    }}
                    value={partner?.terminalCouplingPlatform}
                    onChange={handleCouplingPlatformSelection}
                    options={[
                      {
                        value: PlatformType.secureRetail,
                        label: "Secure Retail",
                      },
                      { value: PlatformType.unitouch, label: "Unitouch" },
                    ]}
                  />

                  <Button
                    onClick={goNext}
                    style={{ alignSelf: "end" }}
                    type="primary"
                    disabled={!partner?.terminalCouplingPlatform}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            )}
            {step === 7 && (
              <div className="center" style={{ flexDirection: "column" }}>
                <Title level={4} style={{ marginBottom: 2 }}>
                  Done !
                </Title>
                <Text style={{ textAlign: "center" }}>
                  Once you create the partner, an invitation will be sent to
                  <span style={{ fontWeight: 700, margin: "0 3px" }}>
                    {partner.email}
                  </span>
                  containing instructions on how to enter the admin console.
                </Text>
                <br />
                <br />
                <Button
                  type="primary"
                  onClick={onCreatePartner}
                  loading={loading}
                  style={{ width: "100%" }}
                >
                  Create Partner
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
