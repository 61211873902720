import { useEffect, useState } from "react";
import {
  IPurchPriceProfile,
  PricingTemplate,
} from "../../../../interfaces/partner.interface";
import {
  Button,
  Divider,
  Form,
  Input,
  Popconfirm,
  Radio,
  Select,
  Space,
  Switch,
  Typography,
} from "antd";
import styles from "./PurchPriceProfileForm.module.css";
import BucketGroupPriceFormItems from "../../../common/bucket-group-price-form-items/BucketGroupPriceFormItems";
import { useAppSelector } from "../../../../app/hooks";
import { selectCurrentPartner } from "../../partnersSlice";
const { Title, Text } = Typography;
interface IPropsPurchPriceProfileForm {
  initialProfile: IPurchPriceProfile;
  onSubmit: (
    value: IPurchPriceProfile & { pricingTemplate: PricingTemplate }
  ) => any;
  hideNameField?: boolean;
  hideWhitelistField?: boolean;
  removeIdField?: boolean;
  loading?: boolean;
}

const purchPriceFieldFieldLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
};
export default function PurchPriceProfileForm({
  initialProfile,
  onSubmit,
  hideNameField = false,
  removeIdField = false,
  hideWhitelistField = false,
  loading = false,
}: IPropsPurchPriceProfileForm) {
  const [form] = Form.useForm();

  const [partnerPricingTemplate, setPartnerPricingTemplate] = useState(
    initialProfile?.pricingTemplate || PricingTemplate.Default
  );
  useEffect(() => {
    form.resetFields();
    populateForm();
  }, [initialProfile]);
  const populateForm = () => {
    if (!initialProfile) return;
    // initialProfile.buckets[0].value = 0.023;
    form.setFieldsValue({
      name: initialProfile?.name,
      pricingTemplate:
        initialProfile?.pricingTemplate || PricingTemplate.Default,
      whitelist: initialProfile?.whitelist || [],
      bucketGroups: initialProfile.bucketGroups,
      _id: initialProfile?._id,
    });
  };

  return (
    <div className={styles.container}>
      <Form onFinish={onSubmit} form={form}>
        <div className={styles.bucketContainer}>
          {!removeIdField && (
            <Form.Item
              label="_id"
              name={"_id"}
              labelCol={{ ...purchPriceFieldFieldLayout.labelCol }}
              labelAlign="left"
              hidden={true}
            >
              <Input />
            </Form.Item>
          )}

          <Form.Item
            label="Whitelist"
            name={"whitelist"}
            labelCol={{ ...purchPriceFieldFieldLayout.labelCol }}
            labelAlign="left"
            hidden={hideWhitelistField}
          >
            <Select
              mode="tags"
              placeholder="Whitelisted kvk numbers..."
              key={"whitelisted-kvk"}
              // onChange={handleChange}
              options={initialProfile?.whitelist?.map((kvk, i) => ({
                label: kvk,
                value: kvk,
                key: `${kvk} ${i}`,
              }))}
            />
          </Form.Item>

          <Form.Item
            label="Name"
            name={"name"}
            labelCol={{ ...purchPriceFieldFieldLayout.labelCol }}
            labelAlign="left"
            hidden={hideNameField}
            required
            rules={[
              {
                required: true,
                message: "Please name the purchase price profile.",
              },
              { min: 1, message: "Name must be at least 1 char." },
            ]}
          >
            <Input style={{ width: "200px" }} />
          </Form.Item>
          {/* <Space
            style={{
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <h4>Allow European Debit Split</h4>

            <Switch
              defaultChecked={splitEuropeanDebit}
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(e) => setSplitEuropeanDebit(e)}
            />
          </Space> */}

          <Form.Item
            label="Pricing Template"
            labelCol={{ ...purchPriceFieldFieldLayout.labelCol }}
            labelAlign="left"
            name={"pricingTemplate"}
          >
            <Radio.Group
              onChange={(e) => setPartnerPricingTemplate(e.target.value)}
              buttonStyle="solid"
            >
              <Radio.Button value={PricingTemplate.Default}>
                Default
              </Radio.Button>
              <Radio.Button value={PricingTemplate.EuropeanDebitSplit}>
                European debit split
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Divider />

          <BucketGroupPriceFormItems
            form={form}
            formItemName="domesticDebitCards"
            label="Domestic Debit Cards"
          />
          {partnerPricingTemplate === PricingTemplate.EuropeanDebitSplit && (
            <BucketGroupPriceFormItems
              form={form}
              formItemName="europeanDebitCards"
              label="E.E.A Debit Cards"
            />
          )}
          <BucketGroupPriceFormItems
            form={form}
            formItemName="european"
            label="E.E.A Cards"
          />
          <BucketGroupPriceFormItems
            form={form}
            formItemName="internationalAndCommercial"
            label="Non E.E.A & Corporate Cards"
          />
        </div>
        <Form.Item>
          <Popconfirm
            title={
              <div style={{ width: "300px" }}>
                Please make sure{" "}
                <span style={{ fontWeight: "bolder" }}>all</span> merchants
                associated to this profile have Rapyd fees within the range. If
                not, change it immediately after saving in both admin console &
                Rapyd’s portal.
              </div>
            }
            style={{ width: "100px" }}
            onConfirm={form.submit}
          >
            <Button
              loading={loading}
              style={{ float: "right" }}
              type="primary"
              htmlType="submit"
            >
              OK
            </Button>
          </Popconfirm>
        </Form.Item>
      </Form>
    </div>
  );
}

{
  /* 
          <Form.List name={"buckets"}>
            {(fields) => (
              <div>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} align="baseline">
                    <Form.Item {...restField} name={[name, "name"]}>
                      <Input
                        className={styles.bucket_name_input}
                        disabled
                      ></Input>
                    </Form.Item>

                    <Form.Item {...restField} name={[name, "value"]}>
                      <InputNumber
                        controls={false}
                        className={styles.bucket_value_input}
                        min={0}
                      />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "valueType"]}>
                      <Select
                        className={styles.bucket_value_input}
                        style={{ width: "85px" }}
                      >
                        <Select.Option value={"Percentage"}>%</Select.Option>
                        <Select.Option value={"Fixed"}>FIXED</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "max"]}>
                      <InputNumber
                        controls={false}
                        className={styles.bucket_value_input}
                        min={0}
                        max={3}
                      />
                    </Form.Item>
                  </Space>
                ))}
              </div>
            )}
          </Form.List>  */
}
